import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok"

const GlossaryPage = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
  let blok = story.content

  return (
    <Layout>
      <SEO title="Glossary" />
      <StoryblokComponent blok={blok} key={blok._uid} />
    </Layout>
  )
}

export default GlossaryPage

export const query = graphql`
  query glossaryQuery {
    storyblokEntry(full_slug: { eq: "glossary" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
